// eslint-disable-next-line no-unused-vars
globalThis.MyDentalBooking = (() => {
  function initDiary(customerReference, autoHeight = true, scrollOffsetTop = 0) {
    const baseHost = "mydentalbooking.co.uk";
    const diaryOrigin = `https://${customerReference}.${baseHost}`;
    let frameSrc = `${diaryOrigin}/Book?HideNav=true&HideLogo=true`;

    if (autoHeight) {
      frameSrc += "&DisableScrolling=true";
    }

    const urlParams = new URLSearchParams(window.location.search);
    const cookieConsent = urlParams.get("MdbCookieConsent");
    if (cookieConsent) {
      frameSrc += "&CookieConsent=true";
    }

    // Load the frame
    const diaryFrame = document.querySelector("iframe#mdb-diary");
    diaryFrame.src = frameSrc;

    document.addEventListener("DOMContentLoaded", () => {
      window.addEventListener("message", (e) => {
        // Ignore untrusted window messages
        if (e.origin !== diaryOrigin && e.origin !== `https://${baseHost}`) {
          return;
        }

        if (autoHeight) {
          if ("height" in e.data) {
            diaryFrame.height = e.data.height;
          }

          if ("scrollTo" in e.data) {
            const rect = diaryFrame.getBoundingClientRect();
            const win = diaryFrame.ownerDocument.defaultView;
            const top = rect.top + win.pageYOffset + e.data.scrollTo - scrollOffsetTop;
            window.scroll({ top, behavior: "smooth" });
          }
        }

        if ("redirect" in e.data) {
          const returnUrl = encodeURIComponent(window.location.href);
          window.location.href = `${e.data.redirect}?ReturnUrl=${returnUrl}`;
        }
      });
    });
  }

  return {
    initDiary,
  };
})();
